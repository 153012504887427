html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  color: $dark
  font-size: 95%
  font-family: $basic
  font-weight: 400
  font-smooth: always
  line-height: 1.6
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $body

a, * a
  color: inherit
  text-decoration: none

.main-hero
  height: calc(100vh - 106px)
  min-height: calc(100vh - 106px)
  max-height: calc(100vh - 106px)

.accordion
  background-color: transparent !important
  .accordion-item
    border-radius: 0 !important
  *
    background-color: transparent !important
    border-left: none !important
    border-right: none !important
  .accordion-button:not(.collapsed)
    color: $dark !important
    background-color: transparent !important

.cta
  &-1
    background: #504857 url('../../assets/images/cta-1.webp') center center no-repeat
    background-size: cover
    background-blend-mode: multiply
  &-2
    background: #504857 url('../../assets/images/cta-2.webp') center center no-repeat
    background-size: cover
    background-blend-mode: overlay

.tour
  background: #5A34B8 url('../../assets/images/tour.webp') center center no-repeat
  background-size: cover

.card
  &-offer
    background: #8E0202 url('../../assets/images/offer.webp') center center no-repeat
    background-size: cover

.card
  &-features
    background: #384577 url('../../assets/images/blue.webp') center center no-repeat
    background-size: cover
  &-pink
    background: #384577 url('../../assets/images/blue.webp') center center no-repeat
    background-blend-mode: soft-light
    background-size: cover
  &-jury
    background: $blue url('../../assets/images/pic-ab-1.webp') center center no-repeat
    background-blend-mode: multiply
    background-size: cover

.item
  position: relative
  display: block
  width: 100%
  height: 100%
  overflow: hidden
  img
    object-fit: cover
    opacity: 1
    transition: all .4s ease-out
  &-content
    position: absolute
    width: calc( 100% - 20px )
    height: calc( 100% - 20px )
    left: 10px
    right: 10px
    top: 120%
    bottom: -120%
    z-index: 1
    opacity: 0
    transition: all .4s ease-out
    border: 10px solid rgba(240, 240, 240, 0.96)
    background-color: rgba(255, 255, 255, 0.86)
    box-shadow: 0 1px 4px rgba(100, 100, 100, 0.2)
  &:hover
    img
      &.work
        opacity: .25
    .item-content
      left: 10px
      right: 10px
      top: 10px
      bottom: 10px
      opacity: 1

.slick-prev:before,
.slick-next:before
  display: block
  color: transparent
  content: ''
  width: 76px
  height: 44px
  opacity: 1

.slick-prev:before
  transform: translateX(-50px)
  background: transparent url('../../assets/svg/arrow-left.svg') center center no-repeat
.slick-next:before
  transform: translateX(-5px)
  background: transparent url('../../assets/svg/arrow-right.svg') center center no-repeat

.slideshow
  &.true
    margin-bottom: -7px

.showcase
  padding: 0 50px

footer
  *
    font-weight: 300
