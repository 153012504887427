*
  font-family: $basic

@for $i from 0 through 300
  .w-#{$i}
    $width: $i * 1%
    width: $width !important
    min-width: $width !important
    max-width: $width !important
    display: block

@for $i from 0 through 1000
  .h-#{$i}
    $height: $i * 1px
    height: $height !important
    min-height: $height !important
    max-height: $height !important
    display: block

@for $i from 0 through 300
  .s-#{$i}
    $height: $i * 1px
    height: $height !important
    clear: both
    display: block

@for $i from 0 through 100
  .h-#{$i}-v
    $height: $i * 1vh
    height: $height !important
    clear: both
    display: block


.ma
  margin-left: auto !important
  margin-right: auto !important
.mla
  margin-left: auto !important
.mra
  margin-right: auto !important

.obfc
  object-fit: cover

.wide
  +v-sm
    min-width: 90% !important
    margin-left: auto
    margin-right: auto
.aic
  align-items: center !important

.aifs
  align-items: flex-start !important

.aife
  align-items: flex-end !important

.jcc
  justify-content: center !important

.jcfs
  justify-content: flex-start !important

.jcfe
  justify-content: flex-end !important

.jcsa
  justify-content: space-around !important

.jcsb
  justify-content: space-between !important

.center
  justify-content: center !important

.tl
  text-align: left !important

.tr
  text-align: right !important
  +v-sm
    text-align: center !important

.tc
  text-align: center !important

.r
  &-16
    border-radius: 16px !important
  &-32
    border-radius: 32px !important
  &-24
    border-radius: 24px !important
  &-8
    border-radius: 8px !important
  &-4
    border-radius: 4px !important

.rowrap
  flex-flow: row wrap

.oh
  overflow: hidden

.flex
  display: flex

.upp
  text-transform: uppercase

lh
  &-1
    line-height: 1 !important
  &-105
    line-height: 1.05 !important
  &-125
    line-height: 1.25 !important
  &-15
    line-height: 1.5 !important
  &-175
    line-height: 1.75 !important
  &-2
    line-height: 2 !important
