header
  height: 80px
  position: relative
  top: 0
  height: 80px
  margin-bottom: -80px
  background: transparent

.site
  &-title
    span
      font-size: .65em
.top
  &-menu
    width: 260px
    display: flex
    align-items: center
    justify-content: flex-end
    &.left
      justify-content: flex-start
    a
      padding-left: 32px
      color: $lgrey
      font-size: .9em
      text-transform: uppercase
      text-decoration: none
      transition: all ease-out .3s
      &:hover
        color: $theme
      span
        margin-left: 8px
  &-nav
    height: 50px
    background-color: $light
    a
      margin: 0 16px
      color: $llgrey
      font-size: .87em
      font-weight: 400
      text-transform: uppercase
      text-decoration: none
      transition: all ease-out .3s
      &:hover
        color: $theme

.burger
  display: none
  +v-sm
    position: fixed
    top: 30px
    right: 50px
    display: block
    span
      width: 24px
      height: 2px
      background-color: $dark
      transition: all ease-out .25s
      &.tt
        position: absolute
        left: 0
        top: 0
      &.mm
        position: absolute
        left: 0
        top: 8px
      &.bb
        position: absolute
        left: 0
        top: 16px
    &.open
      span
        width: 24px
        height: 2px
        background-color: $dark
        &.tt
          position: absolute
          left: 0
          top: 8px
          transform: rotate(45deg)
        &.mm
          width: 0
          position: absolute
          left: 12px
          top: 16px
          background-color: transparent
        &.bb
          position: absolute
          left: 0
          top: 8px
          transform: rotate(-45deg)
  +v-xs
    position: fixed
    top: 28px
    right: 56px
    display: block
